export const photos = [
  {
    src: (process.env.PUBLIC_URL + "/images/allport.jpg"),
    width: 4.5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/pegasus.jpg"),
    width: 4.5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/cusa.jpg"),
    width: 4.5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/staatsloterij-03.jpg"),
    width: 5,
    height: 4
  },
  {
    src: (process.env.PUBLIC_URL + "/images/staatsloterij-02.jpg"),
    width: 5,
    height: 4
  },
  {
    src: (process.env.PUBLIC_URL + "/images/staatsloterij-01.jpg"),
    width: 15,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/happyshoes.jpg"),
    width: 2,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/smartgym.jpg"),
    width: 2,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/efashion.jpg"),
    width: 2,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/tritonsite.jpg"),
    width: 4,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/tritonapp.jpg"),
    width: 3.5,
    height: 6
  },
  {
    src: (process.env.PUBLIC_URL + "/images/netwiredsite.jpg"),
    width: 5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/netwiredapp.jpg"),
    width: 3.5,
    height: 6
  },
  {
    src: (process.env.PUBLIC_URL + "/images/audi.jpg"),
    width: 5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/mustang.jpg"),
    width: 5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/x6.jpg"),
    width: 6,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/lobby.jpg"),
    width: 6,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/conferenceroom.jpg"),
    width: 3,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/receptiondesk.jpg"),
    width: 3,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/livingroom.jpg"),
    width: 3,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/discovery-03.jpg"),
    width: 4,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/discovery-02.jpg"),
    width: 4,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/discovery-01.jpg"),
    width: 4,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/sirte-01.jpg"),
    width: 3,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/sirte-02.jpg"),
    width: 4,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/qatar-01.jpg"),
    width: 5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/qatar-02.jpg"),
    width: 5,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/lyriki-01.jpg"),
    width: 6,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/lyriki-02.jpg"),
    width: 8,
    height: 3
  },
  {
    src: (process.env.PUBLIC_URL + "/images/pano-03.jpg"),
    width: 8,
    height: 2
  },
  {
    src: (process.env.PUBLIC_URL + "/images/pano-01.jpg"),
    width: 8,
    height: 2
  }
];
